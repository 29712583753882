import React from 'react';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';
import Post from './pages/Post';
import Guide from './pages/Guide';
import Jobs from './pages/Jobs';
import Help from './pages/Help';
import './App.css';

function App() {
  
  return (
    <div className="bg-main">
      <nav className="navigation">
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/guide/:id' element={<Guide />} />
          <Route path='/post/:id' element={<Post />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/help' element={<Help />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </nav>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
