import React from 'react'

function NotFound() {

  const handleRedirect = () => {
    window.location.replace('/');
  };

  return (
    <div className='not-found-page-container'>
      <h1 className='not-found-title'>404</h1>
      <h3 className='not-found-description'>Page Not Found</h3>
      <p className='not-found-text'>The page you are looking for might have been removed, changed or is temporarily unavailable.</p>
      <button className='button' onClick={handleRedirect}>Back to Home</button>
    </div>
  )
}

export default NotFound