import React, { useEffect, useState } from 'react';
import Loader from '../components/shared/Loader';

function Terms() {
  const [documentData, setDocumentData] = useState({ content: '', lastModified: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTerms = () => {
      fetch(`https://api.dateflix.app/docs/terms`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to fetch terms');
        }
      })
      .then(data => {
        console.log(data.document);
        setDocumentData(data.document);
      })
      .catch(err => {
        console.error('Error fetching terms:', err);
      })
      .finally(() => setLoading(false));
    }

    fetchTerms();
  }, []);  

  return (
    <div className='terms-page-container opacity-bg'>
      <div className='info-container'>
        <h1 className='page-title'>Terms of Use</h1>
        { loading ? (
           <Loader />
        ) : (
          <div className='document-container'>
             <small className='document-date'>Last Modified: { new Date(documentData.lastModified).toLocaleString('en-GB', { timeZone: 'UTC' }) }</small>
            <div className='page-text doc-content' dangerouslySetInnerHTML={{ __html: documentData.content }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Terms;
