import React from 'react'

function Jobs() {
  return (
    <div className='about-page-container opacity-bg'>
      <div className='info-container'>
        <h1 className='page-title'>Join Us</h1>
        <p className='page-text'>
        At Dateflix, 
        we believe in pushing boundaries, breaking barriers, and creating extraordinary experiences for our users. 
        As we embark on a journey to expand and enhance our app, we are on the lookout for passionate, creative, 
        energetic, enthusiastic, and smart individuals to join our dynamic team.
        </p>
      </div>
      <div className='info-container'>
        <h3 className='page-headline'>Why Join Us?</h3>
        <p className='page-text'>Embrace the opportunity to join us part-time, with the understanding that it's a <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>
          non-paid commitment</span>. 
          However, the invaluable perks extend beyond monetary compensation. By becoming a part of Dateflix, you'll 
          have the chance to learn from brilliant minds, enhance your skill set, and make a substantial impact on a 
          noteworthy project. As one of the inaugural team members of a burgeoning startup, you'll not only enrich 
          your resume but also play a pivotal role in shaping our exciting journey ahead.
          </p>
      </div>
      <div className='info-container'>
        <h3 className='page-headline'>Who We're Looking For:</h3>
        <ul className='job-list'>
          <li className='job-list-item'><span className='list-item-title'>Creative Minds:</span> Are you someone who thinks outside the box? We want individuals who bring fresh 
            perspectives, innovative ideas, and a flair for creativity. Join us in redefining the way users 
            explore and engage with our app.
          </li>
          <li className='job-list-item'><span className='list-item-title'>Enthusiastic Explorers:</span>  Our app is all about exploration and discovery. If you have a natural curiosity and passion 
            for discovering new possibilities, we want you on our team. Be a part of the journey as 
            we explore new horizons and redefine the user experience.
          </li>
          <li className='job-list-item'><span className='list-item-title'>Smart Thinkers:</span> Intelligence is at the core of everything we do. We need individuals who are not just smart, but also 
            have the ability to apply their intelligence to solve complex problems. Join us in building a smarter, 
            more intuitive app for our users.
          </li>
        </ul>
      </div>

      <div className='info-container'>
        <h3 className='page-headline'>Current Opportunities:</h3>
        <p className='page-text'>At present, we do not have any available positions. Kindly revisit in the near 
        future or monitor our <a className='twitter-link' href="https://twitter.com/dateflixapp" rel="noreferrer" target='_blank'>Twitter</a> page for updates on job openings.</p>
      </div>
    </div>
  )
}

export default Jobs