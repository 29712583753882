import React from 'react';

function About() {
  return (
    <div className='about-page-container opacity-bg'>
      <h1 className='page-title' style={{textAlign: 'center'}}>Dateflix</h1>
      <p className='about-text' style={{textAlign: 'center'}}>
        Dateflix is a captivating location discovery app designed to transform your outings into exciting adventures. 
        With Dateflix, users embark on journeys of exploration, unlocking a world of diverse and intriguing locales, 
        neatly organised into captivating categories. These categories are carefully curated to match unique interests, 
        latest trends, and lifestyle preferences. Unveil hidden gems, trendy hotspots, and cozy corners, all at your
        fingertips. Whether you're a foodie, a thrill-seeker or casual explorer, Dateflix ensures every outing is a delightful 
        experience, tailored just for you. Rediscover your city or explore new horizons with Dateflix.
      </p>
    </div>
  )
}

export default About