import React from 'react';
import { Link } from 'react-router-dom';


function Footer() {

  if (window.location.pathname.includes('/guide') || window.location.pathname.includes('/post')) {
    return null;
  }

  return (
    <div className="main-footer">
      <div className='main-footer-content'>

        <div className='social-icons-container'>
          <a href="https://twitter.com/dateflixapp" rel="noreferrer" target='_blank'><img className="social-icon" src="./images/icons/twitter.png" alt="twitter" /></a>
          <a href="https://uk.linkedin.com/company/dateflix?trk=public_post_feed-actor-name" rel="noreferrer" target='_blank'><img className="social-icon" src="./images/icons/linkedin.png" alt="linkedin" /></a>
        </div>
        <ul className="main-footer-links">
          <li><Link to="/help">Help</Link></li>
          <li><Link to="/jobs">Jobs</Link></li>
          <li><Link to="/terms">Terms</Link></li>
          <li><Link to="/privacy">Privacy</Link></li>
        </ul>
        <p className="footer-text">Copyright © Dateflix™ { new Date().getFullYear() }</p>
      </div>
    </div>
  )
}

export default Footer