import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/shared/Loader';

function Guide() {

  const [guide, setGuide] = useState({});
  const [guideLoading, setGuideLoading] = useState(true);

  const { id } = useParams();

  const redirect = () => {
    window.location.replace('https://dateflix.app');
  }

  useEffect(() => {
    const fetchGuide = () => {
      if (id) {
        fetch(`https://api.dateflix.app/posts/guides`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            redirect();
          }
        })
        .then(data => {
          const result = data.results.find(g => g.id === id);

          if (!result) {
            redirect();
          } else {
            setGuide(result);
          }
        })
        .catch(err => {
          redirect();
        })
        .finally(() => setGuideLoading(false));
      } else {
        setGuideLoading(false);
      }
    }
  
    fetchGuide();
  }, [id]);  

  return (
    <div className='article-page-container'>
      { guideLoading ? (
        <Loader />
      ) : (
        <div className='article-article'>
          <div className='article-metadata'>
            <h1 className='article-title'>{guide.title}</h1>
            <h5 className='article-date'>Posted: {guide.posted}</h5>
          </div>
          <div className='article-body' dangerouslySetInnerHTML={{ __html: guide.body }} />

          <div className="categories">
            <span className="category-pill">{guide.category}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Guide