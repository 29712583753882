import React, { useEffect } from 'react';

function Help() {
 
  useEffect(() => {
  }, []);  

  return (
  <div className='help-page-container opacity-bg'>
    <div className='help-content'>
      <h1 className='page-title'>Help Center</h1>
    </div>
  </div>
  );
}

export default Help;
