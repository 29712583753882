import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../components/shared/Loader'

function Post() {

  const [post, setPost] = useState({});
  const [postLoading, setPostLoading] = useState(true);

  const { id } = useParams();

  const redirect = () => {
    window.location.replace('https://dateflix.app');
  }

  useEffect(() => {
    const fetchPost = () => {
      if (id) {
        fetch(`https://api.dateflix.app/posts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            redirect();
          }
        })
        .then(data => {
          const result = data.results.find(p => p.id === id);

          if (!result) {
            redirect();
          } else {
            setPost(result);
          }
        })
        .catch(err => {
          redirect();
        })
        .finally(() => setPostLoading(false));
      } else {
        setPostLoading(false);
      }
    }
  
    fetchPost();
  }, [id]);  

  return (
    <div className='article-page-container'>
      { postLoading ? (
        <Loader />
      ) : (
        <div className='article-article'>
          <div className='article-metadata'>
            <h1 className='article-title'>{post.title}</h1>
            {/* <h3 className='post-description'>{post.description}</h3> */}
            <h5 className='article-date'>Posted: {post.posted}</h5>
          </div>
          <div className='article-body' dangerouslySetInnerHTML={{ __html: post.body }} />

          <div className="categories">
            <span className="category-pill">{post.category}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Post